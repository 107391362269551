export const ApplicationRoutes = {
  account: {
    userProfileManagement: '/user-profile-management',
    companyProfile: '/company-profile',
    login: '/login',
    logout: '/logout',
    resetPassword: '/reset-password',
    confirmEmail: '/confirm-email',
    forgotPassword: '/forgot-password'
  },
  booking: {
    hotelSearchLanding: '/hotel-search',
    hotelSearchResults: '/hotel-search-results',
    bookingHistory: '/booking-history',
    booking: '/booking',
    bookingComplete: '/booking-complete',
    bookingFailure: '/booking-failure'
  }
};
