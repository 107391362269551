import { Box, Flex } from '@chakra-ui/react';
import { NavBar } from './NavBar';
import { Outlet } from 'react-router-dom';

export const MainLayout = () => {
  return (
    <>
      <Flex direction='column' height='100vh' backgroundColor={'#F3F4F7'}>
        <Box position='fixed' top={0} left={0} right={0} zIndex='sticky' bg='white' width='100%'>
          <NavBar />
        </Box>
        {/* The marginTop here is the height of the top nav on desktop or mobile (incl its top border stripe) */}
        <Flex flex='1' overflowY='auto' marginTop={{ base: '55px', sm: '55px', md: '80px' }} id='mainLayoutContainer'>
          <Box width='100%'>
            <Outlet />
          </Box>
          {/*<Box width='100%' textAlign={'center'}>*/}
          {/*  <Heading size={'lg'} paddingTop={'20px'}>*/}
          {/*    Services Unavailable*/}
          {/*  </Heading>*/}
          {/*  <Heading size={'md'} paddingTop={'20px'}>*/}
          {/*    Sorry, Frontier Online is currently unavailable. Please check back later. <br />*/}
          {/*    <br />*/}
          {/*    If your request is urgent, contact our reservations team directly at{' '}*/}
          {/*    <a href={'mailto:reservations@frontierlodging.com'}>reservations@frontierlodging.com</a> or call{' '}*/}
          {/*    <a href={'tel:1-866-468-2488'}>1-866-468-2488</a>.*/}
          {/*  </Heading>*/}
          {/*</Box>*/}
        </Flex>
      </Flex>
    </>
  );
};
