import { ApplicationRoutes } from 'src/constants/applicationRoutes';

export const AuthorizedRoutes = [
  {
    path: ApplicationRoutes.booking.hotelSearchLanding,
    async lazy() {
      const component = await import('src/features/hotelSearch/HotelSearchLanding');
      return { Component: component.HotelSearchLanding };
    }
  },
  {
    path: ApplicationRoutes.booking.hotelSearchResults,
    async lazy() {
      const component = await import('src/features/hotelSearch/HotelSearchResults');
      return { Component: component.HotelSearchResults };
    }
  },
  {
    path: ApplicationRoutes.booking.booking,
    async lazy() {
      const component = await import('src/features/hotelBooking/HotelBooking');
      return { Component: component.HotelBooking };
    }
  },
  {
    path: ApplicationRoutes.booking.bookingComplete,
    async lazy() {
      const component = await import('src/features/hotelBooking/HotelBookingComplete');
      return { Component: component.HotelBookingComplete };
    }
  },
  {
    path: ApplicationRoutes.booking.bookingFailure,
    async lazy() {
      const component = await import('src/features/hotelBooking/HotelBookingFailure');
      return { Component: component.HotelBookingFailure };
    }
  },
  {
    path: ApplicationRoutes.booking.bookingHistory,
    async lazy() {
      const component = await import('src/features/bookingHistory/BookingHistory');
      return { Component: component.BookingHistoryPage };
    }
  },
  {
    path: ApplicationRoutes.account.userProfileManagement,
    async lazy() {
      const component = await import('src/features/userProfileManagement/UserProfileManagement');
      return { Component: component.UserProfileManagement };
    }
  },
  {
    path: ApplicationRoutes.account.companyProfile,
    async lazy() {
      const component = await import('src/features/companyProfileManagement/CompanyProfileManagement');
      return { Component: component.CompanyProfileManagement };
    }
  }
];
