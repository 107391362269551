import { useNavigate } from 'react-router-dom';
import { useAuth } from '@cksoftware/react-base';
import { useHotelBookingStore } from '../../features/stores/useHotelBookingStore';
import { SiteModeObject } from '../../features/misc/HackyNavigationObject';
import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { useCheckPersonalCardAvailableQuery } from '../../features/userProfileManagement/api/checkPersonalCardAvailableQuery';
import { siteModeBusiness, siteModePersonal } from '../../constants/strings';
import { useEffect } from 'react';
import { ApplicationRoles } from '../../constants/appRoles';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBlockQuestion, faBriefcase, faCircleDollar } from '@fortawesome/pro-regular-svg-icons';
import { ApplicationRoutes } from '../../constants/applicationRoutes';

export const SiteModeSelector = () => {
  const nav = useNavigate();
  const auth = useAuth();
  const setHotelSearchState = useHotelBookingStore((state) => state.setSearchQuery);
  const smo = JSON.parse(localStorage.getItem('smo')) as SiteModeObject;

  const { isOpen: isSwitchModeOpen, onOpen: onConfirmModeOpen, onClose: onSwitchModeClose } = useDisclosure();

  const {
    isOpen: isBookingModeHelpOpen,
    onOpen: onBookingModeHelpOpen,
    onClose: onBookingModeHelpClose
  } = useDisclosure();

  const {
    isOpen: isWarnPersonalCardOpen,
    onOpen: onWarnPersonalCardOpen,
    onClose: onWarnPersonalCardClose
  } = useDisclosure();

  const checkPersonalCardAvailableQuery = useCheckPersonalCardAvailableQuery(
    smo && smo.currentMode == siteModePersonal,
    auth.isAuthenticated
  );

  useEffect(() => {
    // Set the default mode to business if we haven't got one.
    if (!smo || [siteModeBusiness, siteModePersonal].indexOf(smo.currentMode) == -1) {
      finishSwitchMode(siteModeBusiness);
      return;
    }

    // If we're in business mode, don't check for personal card at server.
    if (smo.currentMode == siteModeBusiness) {
      return;
    }

    // If we're in personal mode, check for personal card at server.
    if (checkPersonalCardAvailableQuery.data != undefined && checkPersonalCardAvailableQuery.data === false) {
      onWarnPersonalCardOpen();
    }
  }, [checkPersonalCardAvailableQuery.isFetched, checkPersonalCardAvailableQuery.data]);

  const setMode = (newMode: string) => {
    if (smo.currentMode == newMode) {
      return;
    }
    // If we're changing mode and we're NOT on the home page,
    // show confirm dialog.
    if (smo && smo.currentMode != newMode) {
      onConfirmModeOpen();
      return;
    }

    finishSwitchMode(newMode);
  };

  const onSwitchModeClick = () => {
    onSwitchModeClose();
    let newMode = smo.currentMode == siteModeBusiness ? siteModePersonal : siteModeBusiness;
    finishSwitchMode(newMode);
  };

  const finishSwitchMode = (newMode: string) => {
    let siteModeObj: SiteModeObject = {
      currentMode: newMode
    };
    localStorage.setItem('smo', JSON.stringify(siteModeObj));
    setHotelSearchState(undefined);
    window.location.href = '/';
  };

  useEffect(() => {
    if (
      auth.isAuthenticated &&
      auth.isInRole([ApplicationRoles.obtLoa], false) &&
      smo &&
      smo.currentMode == siteModeBusiness
    ) {
      finishSwitchMode(siteModePersonal);
    }
  }, [auth.isAuthenticated]);

  if (!auth.isAuthenticated) {
    return <></>;
  }

  if (auth.isInRole([ApplicationRoles.obtLoa], false)) {
    return <></>;
  }

  if (!smo || (smo && !smo.currentMode)) {
    return <></>;
  }

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          rounded={'full'}
          variant={'outline'}
          cursor={'pointer'}
          minW={0}
          borderColor={smo.currentMode == siteModeBusiness ? 'orange.100' : 'blue.100'}
          color={smo.currentMode == siteModeBusiness ? 'orange.100' : 'blue.100'}
          rightIcon={<ChevronDownIcon />}>
          <FontAwesomeIcon
            icon={smo.currentMode == siteModeBusiness ? faBriefcase : faCircleDollar}
            style={{ paddingRight: '8px' }}
          />
          {smo.currentMode}
        </MenuButton>
        <Portal>
          <MenuList zIndex={'popover'}>
            <MenuGroup title='Site Mode'>
              <MenuItem
                key={0}
                onClick={() => setMode(siteModeBusiness)}
                fontWeight={smo.currentMode == siteModeBusiness ? 'bold' : 'normal'}>
                <FontAwesomeIcon icon={faBriefcase} style={{ paddingRight: '8px' }} />
                {siteModeBusiness}
              </MenuItem>
              <MenuItem
                key={1}
                onClick={() => setMode(siteModePersonal)}
                fontWeight={smo.currentMode == siteModePersonal ? 'bold' : 'normal'}>
                <FontAwesomeIcon icon={faCircleDollar} style={{ paddingRight: '8px' }} />
                {siteModePersonal}
              </MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup title='Help'>
              <MenuItem key={2} onClick={() => onBookingModeHelpOpen()}>
                <FontAwesomeIcon icon={faBlockQuestion} style={{ paddingRight: '8px' }} />
                What is this?
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Portal>
      </Menu>

      {/* Overlay confirm change site mode */}
      <Modal
        closeOnEsc={true}
        closeOnOverlayClick={true}
        onClose={onSwitchModeClose}
        isOpen={isSwitchModeOpen}
        isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader borderBottomWidth='1px'>Switch Site Mode?</ModalHeader>
          <ModalBody>
            <Text paddingTop={'16px'} paddingBottom={'16px'}>
              Switching site mode will restart the booking process from the beginning and lose any current search
              information. Are you sure?
            </Text>
          </ModalBody>
          <ModalFooter borderTopWidth='1px'>
            <Button minW={'100px'} onClick={onSwitchModeClick}>
              Switch
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Overlay confirm change site mode */}
      <Modal
        closeOnEsc={true}
        closeOnOverlayClick={true}
        onClose={onBookingModeHelpClose}
        isOpen={isBookingModeHelpOpen}
        isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader borderBottomWidth='1px'>Site Mode Help</ModalHeader>
          <ModalBody>
            <Text paddingTop={'16px'} paddingBottom={'16px'}>
              This selector changes the booking type from company bookings to personal bookings. These bookings are
              strictly for the personal use of the employees of companies that are contracted with Frontier Lodging
              Solutions.
              <br />
              <br />
              These Frontier hotel rates cannot be used for booking teams or any other group situations. If you use
              these rates for this purpose, your hotel booking may be cancelled by the hotel at their discretion.
              Personal credit card must be used and added in the personal profile section of the site before bookings
              can be made.
            </Text>
          </ModalBody>
          <ModalFooter borderTopWidth='1px'>
            <Button minW={'100px'} onClick={() => onBookingModeHelpClose()}>
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Overlay warn no card on file */}
      <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={onWarnPersonalCardClose}
        isOpen={isWarnPersonalCardOpen}
        isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottomWidth='1px'>Card Needed On File</ModalHeader>
          <ModalBody>
            <Text paddingTop={'16px'} paddingBottom={'16px'}>
              In order to continue with <strong>{siteModePersonal}</strong> bookings, you need to have a personal card
              on file.
            </Text>
          </ModalBody>
          <ModalFooter borderTopWidth='1px'>
            <Button
              minW={'100px'}
              onClick={() => {
                onWarnPersonalCardClose();
                nav(ApplicationRoutes.account.userProfileManagement);
              }}>
              Go to My profile
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
