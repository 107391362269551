import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools'; // import utility to set light and dark mode props
import { tabsAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const colorfulVariant = definePartsStyle((props) => {
  return {
    tab: {
      border: '1px solid',
      borderColor: 'transparent',
      bg: mode(`transparent`, `transparent`),
      borderTopRadius: '16px',
      borderBottom: 'none',
      fontWeight: 'bold',
      _selected: {
        bg: mode('#fff', '#fff')(props),
        color: mode(`orange.100`, `orange.100`)(props),
        borderColor: 'blue.100',
        borderBottom: 'none',
        mb: '-1px'
      }
    },
    tablist: {
      borderBottom: '2x solid',
      borderColor: 'blue.100'
    },
    tabpanel: {
      border: '1px solid',
      borderColor: 'blue.100',
      borderBottomRadius: '16px',
      borderTopRightRadius: '16px',
      backgroundColor: 'white'
    }
  };
});

const variants = {
  customMessageTabs: colorfulVariant
};

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ variants });
